import { useQuery } from '@tanstack/react-query';

import { ToastSeverity, useToast } from 'components/Toast/useToast';
import { useAuth } from 'contexts/Auth/Auth.context';

import { CaseStudiesResponse, CaseStudyResource } from './types';

interface UseCaseStudiesQueryParams {
  technologyId: string;
}

const useCaseStudiesQuery = (params: UseCaseStudiesQueryParams) => {
  const { user } = useAuth();
  const accessToken = user.getToken();
  const toast = useToast();

  return useQuery<CaseStudiesResponse, Error, CaseStudyResource[]>({
    queryKey: ['caseStudies', params, accessToken],
    queryFn: async ({ signal }) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/technologies/${params.technologyId}/caseStudies`,
        {
          signal,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return response.json();
    },
    onError: () => {
      toast({
        severity: ToastSeverity.Error,
        message: "We weren't able to download case studies. Please try again later.",
      });
    },
    select: data => data.items,
    refetchOnMount: false,
  });
};

export default useCaseStudiesQuery;
