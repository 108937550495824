import { useQuery } from '@tanstack/react-query';

import { ToastSeverity, useToast } from 'components/Toast/useToast';
import { useAuth } from 'contexts/Auth/Auth.context';

import { BlogPostResource, BlogPostsResponse } from './types';

interface UseBlogPostsQueryParams {
  technologyId: string;
}

const useBlogPostsQuery = (params: UseBlogPostsQueryParams) => {
  const { user } = useAuth();
  const accessToken = user.getToken();
  const toast = useToast();

  return useQuery<BlogPostsResponse, Error, BlogPostResource[]>({
    queryKey: ['blogPosts', params, accessToken],
    queryFn: async ({ signal }) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/technologies/${params.technologyId}/blogPosts`,
        {
          signal,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return response.json();
    },
    onError: () => {
      toast({
        severity: ToastSeverity.Error,
        message: "We weren't able to download blog posts. Please try again later.",
      });
    },
    select: data => data.items,
    refetchOnMount: false,
  });
};

export default useBlogPostsQuery;
