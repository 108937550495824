import { useMemo, useState } from 'react';

import { Box, HStack, VStack } from '@chakra-ui/react';
import { capitalize, groupBy, isEmpty } from 'lodash';

import Accordion from 'components/Accordion/Accordion';
import InfoTooltip from 'components/InfoTooltip/InfoTooltip';
import SearchInput from 'components/SearchInput/SearchInput';
import SidePanelSectionHeader from 'components/SidePanel/SidePanelSectionHeader/SidePanelSectionHeader';
import Skeleton from 'components/Skeleton/Skeleton';
import { TechnologyDetails } from 'types/technology.types';

import { SearchResultsEmptyLabel, SearchResultsLabel } from '../TechnologyDetailsSearch';
import ResourcesList from './ResourcesList/ResourcesList';
import { BlogPostSource, CaseStudyDomain, Resource, ResourceType } from './types';
import useBlogPostsQuery from './useBlogPostsQuery';
import useCaseStudiesQuery from './useCaseStudiesQuery';

interface TechnologyDetailsResourcesProps {
  technology: TechnologyDetails;
}

const TechnologyDetailsResources = ({ technology }: TechnologyDetailsResourcesProps) => {
  const { id: technologyId, name: technologyName } = technology;
  const { data: caseStudiesData, isLoading: caseStudiesIsLoading } = useCaseStudiesQuery({
    technologyId,
  });
  const { data: blogPostsData, isLoading: blogPostsIsLoading } = useBlogPostsQuery({
    technologyId,
  });
  const [searchPhrase, setSearchPhrase] = useState('');

  const caseStudiesByDomain = useMemo(() => groupBy(caseStudiesData, 'domain'), [caseStudiesData]);
  const blogPostBySource = useMemo(() => groupBy(blogPostsData, 'source'), [blogPostsData]);

  const filteredResources = useMemo<Resource[]>(() => {
    const resourcesCombined = [...(blogPostsData || []), ...(caseStudiesData || [])];
    const searchPhraseLowerCase = searchPhrase.toLowerCase();

    return resourcesCombined.filter(
      ({ title, summary, tags }) =>
        title.toLowerCase().includes(searchPhraseLowerCase) ||
        summary?.toLowerCase().includes(searchPhraseLowerCase) ||
        tags?.some(tag => tag.toLowerCase().includes(searchPhraseLowerCase))
    );
  }, [searchPhrase, blogPostsData, caseStudiesData]);

  const getLabelForCaseStudyGroup = (caseStudyDomain: CaseStudyDomain) => {
    return `${capitalize(caseStudyDomain)} (${
      caseStudiesByDomain[caseStudyDomain]?.length || '0'
    })`;
  };

  const getLabelForBlogPostGroup = (blogPostSource: BlogPostSource) => {
    return `${capitalize(blogPostSource)} (${blogPostBySource[blogPostSource]?.length || '0'})`;
  };

  return (
    <>
      <SidePanelSectionHeader>
        <HStack spacing="8px">
          <span>Resources</span>
          <InfoTooltip tooltipText="Resources consist of Netguru knowledge and case studies gathered in one place. You can review all the resources to a related skill." />
        </HStack>
      </SidePanelSectionHeader>

      <Box mb="32px">
        <SearchInput
          placeholder="Search related resources"
          value={searchPhrase}
          onChange={setSearchPhrase}
        />
      </Box>

      {searchPhrase ? (
        <>
          <SearchResultsLabel itemCount={filteredResources.length} />

          {isEmpty(filteredResources) ? (
            <SearchResultsEmptyLabel>No researches match your search.</SearchResultsEmptyLabel>
          ) : (
            <ResourcesList resources={filteredResources} technologyName={technologyName} />
          )}
        </>
      ) : (
        <VStack spacing="32px" align="stretch">
          <Skeleton isLoaded={!caseStudiesIsLoading}>
            <Accordion label="Case studies" defaultExpanded>
              <VStack spacing="24px" align="stretch">
                <Accordion label={getLabelForCaseStudyGroup(CaseStudyDomain.Behance)} level={2}>
                  <ResourcesList
                    resources={caseStudiesByDomain[CaseStudyDomain.Behance] || []}
                    resourceType={ResourceType.CaseStudy}
                    technologyName={technologyName}
                  />
                </Accordion>
                <Accordion label={getLabelForCaseStudyGroup(CaseStudyDomain.Dribbble)} level={2}>
                  <ResourcesList
                    resources={caseStudiesByDomain[CaseStudyDomain.Dribbble] || []}
                    resourceType={ResourceType.CaseStudy}
                    technologyName={technologyName}
                  />
                </Accordion>
                <Accordion label={getLabelForCaseStudyGroup(CaseStudyDomain.Netguru)} level={2}>
                  <ResourcesList
                    resources={caseStudiesByDomain[CaseStudyDomain.Netguru] || []}
                    resourceType={ResourceType.CaseStudy}
                    technologyName={technologyName}
                  />
                </Accordion>
              </VStack>
            </Accordion>
          </Skeleton>

          <Skeleton isLoaded={!blogPostsIsLoading}>
            <Accordion label="R&D" defaultExpanded>
              <VStack spacing="24px" align="stretch">
                <Accordion label={getLabelForBlogPostGroup(BlogPostSource.Confluence)} level={2}>
                  <ResourcesList
                    resources={blogPostBySource[BlogPostSource.Confluence] || []}
                    resourceType={ResourceType.CaseStudy}
                    technologyName={technologyName}
                  />
                </Accordion>
                <Accordion label={getLabelForBlogPostGroup(BlogPostSource.External)} level={2}>
                  <ResourcesList
                    resources={blogPostBySource[BlogPostSource.External] || []}
                    resourceType={ResourceType.CaseStudy}
                    technologyName={technologyName}
                  />
                </Accordion>
              </VStack>
            </Accordion>
          </Skeleton>

          <Skeleton isLoaded={!blogPostsIsLoading}>
            <Accordion label="Blog articles" defaultExpanded>
              <VStack spacing="24px" align="stretch">
                <ResourcesList
                  resources={blogPostBySource[BlogPostSource.NetguruBlog] || []}
                  resourceType={ResourceType.Article}
                  technologyName={technologyName}
                  shouldDisplayTags={false}
                  shouldDisplaySummary={false}
                />
              </VStack>
            </Accordion>
          </Skeleton>
        </VStack>
      )}
    </>
  );
};

export default TechnologyDetailsResources;
