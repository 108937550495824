import { useState } from 'react';

import { Grid, GridItem, HStack } from '@chakra-ui/react';

import RadioCardSmall from 'components/CardButton/RadioCardSmall/RadioCardSmall';
import { Combobox, Item } from 'components/Combobox';
import InfoTooltip from 'components/InfoTooltip/InfoTooltip';
import SidePanelSectionHeader from 'components/SidePanel/SidePanelSectionHeader/SidePanelSectionHeader';
import Skeleton from 'components/Skeleton/Skeleton';
import Tooltip from 'components/Tooltip/Tooltip';

import StatisticsDataCard from './StatisticsDataCard';
import { ComparisonUnit, StatisticsPeriod } from './types';
import useStatisticsQuery from './useStatisticsQuery';

interface TechnologyDetailsStatisticsProps {
  technologyId: string;
}

const TechnologyDetailsStatistics = ({ technologyId }: TechnologyDetailsStatisticsProps) => {
  const [statisticsPeriod, setStatisticsPeriod] = useState<StatisticsPeriod>('30');
  const [comparisonUnit, setComparisonUnit] = useState<ComparisonUnit>('number');

  const queryResult = useStatisticsQuery({ technologyId, statisticsPeriod });

  if (queryResult.isError) {
    return null;
  }

  return (
    <div>
      <SidePanelSectionHeader>
        <HStack spacing="8px">
          <span>Statistics</span>
          <InfoTooltip tooltipText="Current values and the change over selected period." />
        </HStack>
      </SidePanelSectionHeader>

      <Grid templateColumns="repeat(4, 1fr)" gap="12px">
        <GridItem colSpan={2}>
          <Combobox
            aria-label="Statistics period"
            selectedKey={statisticsPeriod}
            onSelectionChange={key => {
              setStatisticsPeriod(key as StatisticsPeriod);
            }}
          >
            <Item key="30">Last 30 days</Item>
            <Item key="90">Last 90 days</Item>
            <Item key="365">Last 365 days</Item>
          </Combobox>
        </GridItem>
        <GridItem colSpan={1} justifyContent="stretch">
          <Tooltip content="Show change as number." placement="top">
            <div>
              <RadioCardSmall
                value="number"
                label="#"
                checked={comparisonUnit === 'number'}
                onChange={() => setComparisonUnit('number')}
                fullWidth
              />
            </div>
          </Tooltip>
        </GridItem>
        <GridItem colSpan={1}>
          <Tooltip content="Show change as percentage." placement="top">
            <div>
              <RadioCardSmall
                value="percentage"
                label="%"
                checked={comparisonUnit === 'percentage'}
                onChange={() => setComparisonUnit('percentage')}
                fullWidth
              />
            </div>
          </Tooltip>
        </GridItem>
        <GridItem colSpan={2}>
          <Skeleton isLoaded={!queryResult.isLoading}>
            <Tooltip
              content="Top number represents the number of active projects that used selected skill during specified period. Bottom number indicates increase / decrease in the numbers of active projects (in comparison to previous period)."
              placement="bottom-end"
            >
              <div>
                <StatisticsDataCard
                  title="Projects"
                  value={queryResult.data?.projectsCount || 0}
                  diff={queryResult.data?.projectsDiff || 0}
                  unit={comparisonUnit}
                />
              </div>
            </Tooltip>
          </Skeleton>
        </GridItem>
        <GridItem colSpan={2}>
          <Skeleton isLoaded={!queryResult.isLoading}>
            <Tooltip
              content="Top value represents the number of opportunities for selected skill during specified period. Bottom value indicates increase / decrease in the numbers of opps (in comparison to previous period)."
              placement="bottom-end"
            >
              <div>
                <StatisticsDataCard
                  title="Opps"
                  value={queryResult.data?.oppsCount || 0}
                  diff={queryResult.data?.oppsDiff || 0}
                  unit={comparisonUnit}
                />
              </div>
            </Tooltip>
          </Skeleton>
        </GridItem>
        <GridItem colSpan={2}>
          <Skeleton isLoaded={!queryResult.isLoading}>
            <Tooltip
              content="Top value represents the number of leads for selected skill during specified period. Bottom value indicates increase / decrease in the numbers of leads (in comparison to previous period)."
              placement="bottom-end"
            >
              <div>
                <StatisticsDataCard
                  title="Leads"
                  value={queryResult.data?.leadsCount || 0}
                  diff={queryResult.data?.leadsDiff || 0}
                  unit={comparisonUnit}
                />
              </div>
            </Tooltip>
          </Skeleton>
        </GridItem>
        <GridItem colSpan={2}>
          <Skeleton isLoaded={!queryResult.isLoading}>
            <Tooltip
              content="Top value represents the number of talents with selected skill during specified period. Bottom value indicates increase/decrease in the numbers of talents (in comparison to previous period)."
              placement="bottom-end"
            >
              <div>
                <StatisticsDataCard
                  title="Talents"
                  value={queryResult.data?.talentsCount || 0}
                  diff={queryResult.data?.talentsDiff || 0}
                  unit={comparisonUnit}
                />
              </div>
            </Tooltip>
          </Skeleton>
        </GridItem>
      </Grid>
    </div>
  );
};

export default TechnologyDetailsStatistics;
